<template>
  <v-container>
    <h3>{{ $t("message.title-edit-category") }}</h3>
    <v-col>
      <form @submit.prevent="updateCategory">
        <v-row>
          <v-col cols="12" md="2" xs2 class="lable-class">
            <span class="label">{{ $t("message.label-id") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs2 class="input-class">
            <v-text-field disabled v-model="categoryDetail.id"></v-text-field>
          </v-col>
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span class="label" >{{ $t("message.label-name") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-text-field
              outlined
              required
              :error-messages="nameErrors"
              @input="$v.edited.category_name.$touch()"
              @blur="$v.edited.category_name.$touch()"
              v-model="edited.category_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span class="label">{{ $t("message.label-status") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-select
              v-model="edited.is_active"
              :items="statusList"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </v-col>

          <!-- Icon Category -->
          <v-col cols="12" md="2" xs="2" class="label-class">
            <span class="label">{{ $t("message.label-image") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="input-class">
            <v-file-input
              class="file-input-class"
              type="file"
              ref="fileImg"
              v-model="images"
              @change="upload"
              accept="image/*"
              outlined
              id="file-input"
              required
            >
            </v-file-input>
            <v-row v-if="isLoadingImg" class="fill-height ma-0">
              <v-progress-circular
                indeterminate
                color="blue"
              ></v-progress-circular>
            </v-row>
          </v-col>
          <template>
            <v-container class="">
              <v-row class="mb-6" no-gutters>
                <v-col>

                  <v-img
                    :src="uploadImg"
                    max-height="100px"
                    max-width="100px"
                    class=""
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <!-- -->
          <div class="fixed-div">
            <v-row cols="12" class="row-btn">
              <v-col cols="4" md="9" xs="1" class="btn-col">
                <v-btn
                  class="btn-back-class"
                  rounded
                  color="secondary"
                  dark
                  @click.prevent="goBack"
                >
                  {{ $t("message.btn-back") }}
                </v-btn>
              </v-col>
              <v-col cols="4" md="1" xs="1" class="btn-col">
                <v-btn
                  type="submit"
                  class="btn-edit-class"
                  rounded
                  color="primary"
                  dark
                  @click.prevent="updateCategory"
                >
                  {{ $t("message.btn-update") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </form>
    </v-col>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditCategory",
  mixins: [validationMixin],

  validations: {
    edited: {
      category_name: { required },
      category: { required },
      status: { required },
    }
  },

  data: () => ({
    statusList: [
      { id:1, name: "Active" },
      { id:0, name: "Non Active" },
    ],
    name: "",
    selectedStatus: "",
    isLoading: false,
    id: "",
    edited: {
      category_name: "",
      is_active: "",
    },
    message: {},
    isLoadingImg: false,
    images: null
  }),

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.edited.category_name.$dirty) return errors;
      // !this.$v.edited.category_name.required && errors.push("Name is required.");
      !this.$v.edited.category_name.required &&
        errors.push(this.$t("message.err-msg-name-category"))
      return errors;
    },
    categoryDetail(){
      return this.$store.state.category_module.categoryDetail
    },
    uploadImg() {
      return this.$store.state.category_module.imageLink;
    }
  },
  created(){
    this.id = localStorage.getItem("editCategoryId")
    this.fetchCategoryDetail();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    updateCategory(){
      this.message.title = this.$t("message.title-update-category-success-message")
      this.edited.image_url = this.uploadImg
      this.$store.dispatch("category_module/updateCategory", {
        id: this.$route.params.id,
        data: this.edited,
        message: {
          title: this.message.title
        }
      });
      this.$router.push("/categoryMaster");
    },
    async fetchCategoryDetail(){
      let id = this.$router.history.current.params.id
      await this.$store.dispatch('category_module/fetchCategoryDetail', id)

      let categoryDetail = this.$store.state.category_module.categoryDetail;

      this.edited.id = categoryDetail.id
      this.edited.category_name = categoryDetail.category_name
      this.edited.is_active = categoryDetail.is_active
    },
    async upload() {
      if (this.images != null) {
        await this.$store.dispatch("category_module/upload", {
          images: this.images,
          path: "categories",
        });
      }else{
        await this.$store.dispatch("category_module/clearImg");
      }
    }
  },

};
</script>

<style>
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
  color: #3f51b5;
  font-weight: bold;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
</style>
